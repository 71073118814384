@import '~b2b-apps/core/common/components/InfoView/InfoView';

.InfoView--ImageRight {
  align-items: center;
  flex-direction: row-reverse;
  justify-content: center;

  .InfoView-ImageWrapper {
    margin: 0;
  }

  .InfoView-Content {
    margin-bottom: 0;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column-reverse;
  }
}

.InfoView-ImageWrapper {
  height: auto;
  margin-bottom: $cds-spacing-6;
  width: auto;
}

.InfoView-Image {
  max-height: none;
  max-width: 100%;
}
